import { FC, useState } from 'react';
import { useInterval } from 'react-use';
import { formatTimeDifference, getTimeDifference } from './Countdown.helpers';

interface Props {
  countdown: Date | null;
}

export const CountdownContent: FC<Props> = ({ countdown }) => {
  const [timeLeft, setTimeLeft] = useState<string>(formatTimeDifference(getTimeDifference(countdown)));

  useInterval(() => {
    const diff = getTimeDifference(countdown);
    if (diff === null || diff <= 0) {
      setTimeLeft('0:00:00');
    } else {
      setTimeLeft(formatTimeDifference(diff));
    }
  }, 1000);

  return timeLeft;
};
