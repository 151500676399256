import { clsx } from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { IconCaretDown } from 'src/general/Icons/IconCaretDown';
import { IconCaretUp } from 'src/general/Icons/IconCaretUp';
import { IconMenu } from 'src/general/Icons/IconMenu';
import { getUserInitials } from 'src/general/helpers/getUserInitials';
import { authStore } from 'src/stores/authStore';
import { cartStore } from 'src/stores/cartStore';

import { UserAvatar } from '../../UserAvatar/UserAvatar';
import styles from './PopperTrigger.module.scss';

interface Props {
  onClick: () => void;
  popperOpen: boolean;
}

export const PopperTrigger = forwardRef<HTMLButtonElement, Props>(({ onClick, popperOpen }, ref) => {
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const { userData, userDataLoading } = authStore(
    ({ userData, userDataLoading }) => ({ userData, userDataLoading }),
    shallow,
  );
  const { cartActive } = cartStore((state) => {
    const cartActive = state.cartExpiry && !state.cartExpired;
    return { cartActive };
  }, shallow);

  const userInitials = getUserInitials(userData);

  useEffect(() => {
    if (!userDataLoading) {
      setFirstLoaded(true);
    }
  }, [userDataLoading]);

  return (
    <button
      ref={ref}
      className={clsx(styles.root, {
        [styles.loggedIn]: userInitials !== '',
        [styles.loading]: userDataLoading,
        [styles.popperOpen]: popperOpen,
      })}
      disabled={!firstLoaded}
      aria-busy={!firstLoaded}
      data-testid="HeaderMenu-menu-button"
      onClick={onClick}
      title="Menu"
    >
      <IconMenu className={styles.menuIcon} />
      <UserAvatar loading={userDataLoading} notification={!!cartActive} userData={userData} />
      <div className={styles.caretContainer}>
        {popperOpen ? <IconCaretUp className={styles.caretIcon} /> : <IconCaretDown className={styles.caretIcon} />}
      </div>
    </button>
  );
});

PopperTrigger.displayName = 'PopperTrigger';
