import { clsx } from 'clsx';
import Link from 'next/link';
import { FC } from 'react';
import { shallow } from 'zustand/shallow';

import { authStore } from 'src/stores/authStore';

import { Button } from '../../Button/Button';
import { Chevron } from '../../Chevron/Chevron';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { HeaderMenuData, HeaderMenuNavigationItem } from '../HeaderMenu.type';
import styles from './Menu.module.scss';

interface Props {
  headerData: HeaderMenuData;
  className?: string;
  setCurrentMenu: (currentMenu: HeaderMenuNavigationItem | null) => void;
  onItemClick?: () => void;
}

export const MenuRoot: FC<Props> = ({ headerData, className, setCurrentMenu, onItemClick }) => {
  const { isUserLoggedIn, userData } = authStore(
    ({ isUserLoggedIn, userData }) => ({ isUserLoggedIn, userData }),
    shallow,
  );

  return (
    <ul className={clsx(styles.menu, className)}>
      {/* Account/Sign-in */}
      {isUserLoggedIn ? (
        <li className={styles.listItemBorderBottom}>
          <button
            className={clsx(styles.menuItem, styles.menuItemEndIcon)}
            onClick={() => {
              setCurrentMenu({ label: 'My account', type: 'account' });
            }}
          >
            <span className={styles.menuItemAccount}>
              <UserAvatar userData={userData} />
              My account
            </span>
            <Chevron />
          </button>
        </li>
      ) : (
        <li>
          <Button href="/signin" variant="primary" className={styles.button} fullWidth onClick={onItemClick}>
            Login or create an account
          </Button>
        </li>
      )}
      {/* Website pages  */}
      {headerData.navigation.map((item) => {
        switch (item.type) {
          case 'link':
            return (
              <li key={item.label}>
                <Link href={item.href} onClick={onItemClick} className={styles.menuItem}>
                  {item.label}
                </Link>
              </li>
            );
          case 'submenu_faq':
            return (
              <li key={item.label}>
                <button
                  className={clsx(styles.menuItem, styles.menuItemEndIcon)}
                  onClick={() => {
                    setCurrentMenu(item);
                  }}
                >
                  {item.label}
                  <Chevron />
                </button>
              </li>
            );
          // Add other menu types here
          default:
            return null;
        }
      })}
    </ul>
  );
};
