import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconClose: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="close">
    <path d="M12 11.1516L6.9999 6.15146L6.15137 6.99999L11.1515 12.0001L6.15158 17L7.0001 17.8485L12 12.8486L16.9999 17.8485L17.8484 17L12.8485 12.0001L17.8486 6.99999L17.0001 6.15146L12 11.1516Z" />
  </Icon>
);
