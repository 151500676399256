import { clsx } from 'clsx';
import { ComponentProps, FC } from 'react';

import { IconClock } from 'src/general/Icons/IconClock';

import styles from './Countdown.module.scss';
import { CountdownContent } from './CountdownContent';

export const Countdown: FC<ComponentProps<typeof CountdownContent>> = (props) => (
  <section className={clsx(styles.root, { [styles.unavailable]: props.countdown === null })}>
    <div className={styles.imageWrapper}>
      <IconClock />
    </div>
    <div className={styles.countdown} data-testid="COUNTDOWN_TIME_LEFT">
      <CountdownContent {...props} />
    </div>
  </section>
);
