import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconArrowLeft: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="arrow-left">
    <path d="M4.576 12.424 11 18.85 11.85 18l-5.4-5.4H19.6v-1.2H6.45l5.4-5.4L11 5.152l-6.424 6.424a.6.6 0 0 0 0 .848Z" />
  </Icon>
);
