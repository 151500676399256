import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconCaretDown: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="caret-down">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.151 9 6.425 6.424a.6.6 0 0 0 .848 0L18.848 9 18 8.151l-6 6-6-6L5.15 9Z"
    />
  </Icon>
);
