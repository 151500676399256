import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconCaretUp: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="caret-up">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.848 15-6.424-6.424a.6.6 0 0 0-.848 0L5.15 15l.849.849 6-6 6 6 .848-.849Z"
    />
  </Icon>
);
