import dynamic from 'next/dynamic';
import { ComponentProps, FC } from 'react';

const TRANSITION_DURATION = 0.3;

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: TRANSITION_DURATION,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: TRANSITION_DURATION,
      ease: 'easeInOut',
    },
  },
};

const Transition = dynamic(() => import('./Transition').then((mod) => mod.Transition), { ssr: false });

export const Fade: FC<ComponentProps<typeof Transition>> = (props) => <Transition {...props} variants={variants} />;
