import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconAvatar: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="avatar">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM14.8 8C14.8 9.5464 13.5464 10.8 12 10.8C10.4536 10.8 9.2 9.5464 9.2 8C9.2 6.4536 10.4536 5.2 12 5.2C13.5464 5.2 14.8 6.4536 14.8 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9999 12.4C7.45939 12.4 5.3999 14.4595 5.3999 17V17.8329C5.3999 18.5503 5.69635 19.2972 6.35129 19.7502C7.53578 20.5695 9.60792 21.6 11.9999 21.6C14.3919 21.6 16.464 20.5695 17.6485 19.7502C18.3035 19.2972 18.5999 18.5503 18.5999 17.8329V17C18.5999 14.4595 16.5404 12.4 13.9999 12.4H9.9999ZM6.5999 17C6.5999 15.1222 8.12213 13.6 9.9999 13.6H13.9999C15.8777 13.6 17.3999 15.1222 17.3999 17V17.8329C17.3999 18.2386 17.2345 18.5774 16.9659 18.7633C15.9004 19.5002 14.0647 20.4 11.9999 20.4C9.9351 20.4 8.09939 19.5002 7.03393 18.7633C6.76527 18.5774 6.5999 18.2386 6.5999 17.8329V17Z"
    />
  </Icon>
);
