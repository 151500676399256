import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconCaretRightFilters: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="caret-right-filters">
    <path d="M14.424 11.576 11 8.15 10.15 9l3 3-3 3 .849.848 3.424-3.424a.6.6 0 0 0 0-.848Z" />
  </Icon>
);
