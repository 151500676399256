import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconArrowRight: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="arrow-right">
    <path d="M19.424 11.576 13 5.15 12.15 6l5.4 5.4H4.4v1.2H17.55l-5.4 5.4.849.848 6.424-6.424a.6.6 0 0 0 0-.848Z" />
  </Icon>
);
